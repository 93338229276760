import React from "react";
import LogoTag from "../../atoms/logo";
import "./style.scss";
import { StyledButton } from "../../atoms/button";

const IntroText = (props) => (
  <div id="introText">
    <LogoTag width="218"></LogoTag>
    <h2>{props.title}</h2>
    <p>{props.description}</p>
    <StyledButton tag="a" size="lg2" href={props.buttonHref} bg="light">
      Learn more
    </StyledButton>
  </div>
);

export default IntroText;
