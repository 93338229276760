import React from "react";
import "./style.scss";

export const ValidationInput = ({
  invalidMsg,
  _ref,
  label,
  labelPosition,
  ...attr
}) => {
  return (
    <React.Fragment>
      {(labelPosition === "left" || labelPosition === "top") && label ? (
        <label className="form-label" data-position={labelPosition}>
          {label}
        </label>
      ) : null}
      <input className="form-control" ref={_ref} {...attr} />
      {(labelPosition === "right" || labelPosition === "bottom") && label ? (
        <label className="form-label" data-position={labelPosition}>
          {label}
        </label>
      ) : null}
      {invalidMsg ? <div className="invalid-feedback">{invalidMsg}</div> : null}
    </React.Fragment>
  );
};

export const ValidationCheckbox = ({
  invalidMsg,
  _ref,
  label,
  labelPosition,
  id,
  ...attr
}) => {
  return (
    <React.Fragment>
      {(labelPosition === "left" || labelPosition === "top") && label ? (
        <label className="form-label" data-position={labelPosition} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <input className="form-control" id={id} ref={_ref} {...attr} />
      {(labelPosition === "right" || labelPosition === "bottom") && label ? (
        <label className="form-label" data-position={labelPosition} htmlFor={id}>
          {label}
        </label>
      ) : null}
      {invalidMsg ? <div className="invalid-feedback">{invalidMsg}</div> : null}
    </React.Fragment>
  );
};

export const ValidationTextArea = ({
  invalidMsg,
  _ref,
  label,
  labelPosition,
  ...attr
}) => {
  return (
    <React.Fragment>
      {(labelPosition === "left" || labelPosition === "top") && label ? (
        <label className="form-label" data-position={labelPosition}>
          {label}
        </label>
      ) : null}
      <textarea className="form-control" ref={_ref} {...attr}></textarea>
      {(labelPosition === "right" || labelPosition === "bottom") && label ? (
        <label className="form-label" data-position={labelPosition}>
          {label}
        </label>
      ) : null}
      {invalidMsg ? <div className="invalid-feedback">{invalidMsg}</div> : null}
    </React.Fragment>
  );
};

export const ValidationSelectBox = ({
  invalidMsg,
  _ref,
  label,
  labelPosition,
  children,
  ...attr
}) => {
  return (
    <React.Fragment>
      {(labelPosition === "left" || labelPosition === "top") && label ? (
        <label className="form-label" data-position={labelPosition}>
          {label}
        </label>
      ) : null}
      <select className="form-select" ref={_ref} {...attr}>
        {children}
      </select>
      {(labelPosition === "right" || labelPosition === "bottom") && label ? (
        <label className="form-label" data-position={labelPosition}>
          {label}
        </label>
      ) : null}
      {invalidMsg ? <div className="invalid-feedback">{invalidMsg}</div> : null}
    </React.Fragment>
  );
};
