import React, { useState } from "react";
import SVG from "react-inlinesvg";
import "./style.scss";
import { StyledButton } from "../../atoms/button";
import { useEffect } from "react";

export const ValidationRadioButton = ({
  name,
  label,
  list,
  value,
  showValidate,
  onClick,
  invalidMsg,
  align,
  ...attr
}) => {

  return (
    <div className="detail-info-area" style={{ marginTop: 10 }}>
      <ul>
        <li>
          {label && <strong>{label}</strong>}
          <span
            data-align={align ? align : null}
          >
            {list.map((el, i) => {
              return (
                <span
                  key={i}
                  className="iv-base-label"
                  onClick={() => onClick(el.value)}
                >
                  <input
                    type="radio"
                    name={name}
                    checked={value === el.value}
                    onChange={() => onClick(el.value)}
                    {...attr}
                  ></input>
                  <label htmlFor={name}>{el.label}</label>
                </span>
              );
            })}
          </span>
          {showValidate && value == null && (
            <div style={{ color: "#EB5757" }}>{invalidMsg}</div>
          )}
        </li>
      </ul>
    </div>
  );
};
export const ValidationInput = ({ _ref, hint, invalidMsg, onChange, label, showValidate, ...attr }) => {
  const [validate, setValidate] = useState(false)
  let timer = null;
  return (
    <div className="detail-info-area" style={{ marginTop: 10 }}>
      <ul>
        <li className={attr.className}>
          {label && <strong>{label}</strong>}
          <span className={attr.className}>
            <input
              type="text"
              ref={_ref}
              onChange={(e) => {
                onChange(e);
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (invalidMsg) setValidate(e.target.value === "");
                }, 400);
              }}
              {...attr}
            ></input>
          </span>
          {(validate || showValidate) && attr.value === "" && (
            <div style={{ color: "#EB5757" }}>{invalidMsg}</div>
          )}
          {hint && (<small style={{ marginTop: "5px", color: "grey" }}>*{hint}</small>)}
        </li>
      </ul>
    </div>
  );
};

export const ValidationInputWithButton = ({ _ref, hint, invalidMsg, onChange, label, showValidate,onClick,value,onDelete, ...attr }) => {
  const [validate, setValidate] = useState(false)
  let timer = null;
  return (
    <div className="detail-info-area" style={{ marginTop: 10 }}>
      <ul>
        <li className={attr.className}>
          {label && <strong>{label}</strong>}
            <span className={attr.className} style={{border:"1px solid #b3b3b3",borderRadius:"0.4rem"}}>
            <input
                style={{border:"none"}}
              type="text"
              ref={_ref}
              value={value}
                onChange={(e) => {return;}}
              {...attr}
            >
            </input>
            <StyledButton
                     style={{left:"-15px",backgroundColor:"#acb8d2"}}
              size='md'
              tag='button'
              type='icon'
              data-bg='primary'
              onClick={(e) => onClick(e)}
            >
              <SVG
                src='assets/images/common/icon_details.svg'
                fill='#ffffff'></SVG>
            </StyledButton>
            {value !== "" ? <StyledButton
                  style={{left:"-10px",backgroundColor:"#eb5656"}}
              size='md'
              tag='button'
              type='icon'
              data-bg='cancel'
              onClick={(e) => onDelete(e)}
            >
              <SVG
                onClick={(e) => onDelete(e)}
                src='assets/images/common/icon_cancel.svg'
                fill='#ffffff'></SVG>
              </StyledButton>:<></>}
          </span>
          {(validate || showValidate) && attr.value === "" && (
            <div style={{ color: "#EB5757" }}>{invalidMsg}</div>
          )}
        </li>
      </ul>
    </div>
  );
};

export const ValidationTextArea = ({
  hint,
  invalidMsg,
  onChange,
  label,
  value,
  showValidate,
  ...attr
}) => {
  const [validate, setValidate] = useState(false);
  let timer = null;
  return (
    <div className="detail-info-area" style={{ marginTop: 10 }}>
      <ul>
        <li>
          <strong>{label}</strong>
          <span>
            <textarea
              type="text"
              onChange={(e) => {
                onChange(e);
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (invalidMsg) setValidate(e.target.value === "");
                }, 400);
              }}
              value={value}
              {...attr}
            >
              {" "}
            </textarea>
          </span>
          {(validate || showValidate) && value === "" && (
            <div style={{ color: "#EB5757" }}>{invalidMsg}</div>
          )}
          {hint && (<small style={{ marginTop: "5px", color: "grey" }}>*{hint}</small>)}
        </li>
      </ul>
    </div>
  );
};

export const ValidationSelectBox = ({
  _ref,
  hint,
  invalidMsg,
  label,
  children,
  showValidate,
  ...attr
}) => {
  let timer = null;
  const [validate, setValidate] = useState(false);
  return (
    <div className="detail-info-area" style={label ? { marginTop: 10 } : null}>
      {label ? (
        <ul>
          <li>
            <strong>{label}</strong>
            <span>
              <select
                ref={_ref}
                {...attr}
                onChange={(e) => {
                  attr.onChange(e);
                  clearTimeout(timer);
                  timer = setTimeout(() => {
                    if (invalidMsg) setValidate(e.target.value === "");
                  }, 400);
                }}
              >
                {children}
              </select>
            </span>
            {(validate || showValidate) && attr.value === "" && (
              <div style={{ color: "#EB5757" }}>{invalidMsg}</div>
            )}
            {hint && (<small style={{ marginTop: "5px", color: "grey" }}>*{hint}</small>)}
          </li>
        </ul>
      ) : (
        <>
          <span>
            <select
              ref={_ref}
              {...attr}
              onChange={(e) => {
                attr.onChange(e);
                clearTimeout(timer);
                timer = setTimeout(() => {
                  if (invalidMsg) setValidate(e.target.value === "");
                }, 400);
              }}
            >
              {children}
            </select>
          </span>
          {(validate || showValidate) && attr.value === "" && (
            <div style={{ color: "#EB5757" }}>{invalidMsg}</div>
          )}
          {hint && (<small style={{ marginTop: "5px", color: "grey" }}>*{hint}</small>)}
        </>
      )}
    </div>
  );
};

export const ValidationSelectBox2 = ({
  validchk = "",
  invalidMsg,
  _ref,
  label,
  labelPosition,
  optionData,
  value,
  onChange,
  ...attr
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  useEffect(() => {
    const arr = optionData.filter((d) => d.value === value);
    if (arr.length > 0) setCurrentValue(arr[0].label);
  }, [value]);
  return (
    <>
      <span
        data-type="select-custom"
        className={
          validchk
            ? validchk + " " + "iv-base-select valid-chk"
            : "iv-base-select"
        }
        data-position={labelPosition}
      >
        <span className="form-box">
          <div
            className={`iv-data-connect ${showOptions && "on"}`}
            onClick={() => setShowOptions((prev) => !prev)}
          >
            <strong className="iv-data-view">{currentValue}</strong>
            <ul className={`iv-data-select `}>
              {optionData.map((data, idx) => (
                <li
                  className="iv-data-option"
                  key={idx}
                  data-value={data.value}
                  onClick={() => onChange(data.value)}
                >
                  <span>{data.label}</span>
                </li>
              ))}
            </ul>
          </div>
          {validchk ? (
            <>
              <StyledButton
                data-bg="transparent"
                type="icon"
                data-outline="transparent"
                size="sm"
                className="icon-valid"
              >
                <SVG
                  src="assets/images/common/icon_valid.svg"
                  fill={validchk === "success" ? "#198754" : "#EB5757"}
                ></SVG>
              </StyledButton>
            </>
          ) : null}
        </span>
      </span>
      {invalidMsg ? (
        <>
          <div className="invalid-feedback">{invalidMsg}</div>
        </>
      ) : null}
    </>
  );
};
export const TableInputs = ({
  label,
  headers = [],
  datas = [],
  onSubmit = () => { },
}) => {
  const [keyInput, setKeyInput] = useState("");
  const [valueInput, setValueInput] = useState("");

  return (
    <div className="detail-info-area" style={{ marginTop: 20 }}>
      <ul>
        <li>
          <strong>{label}</strong>
          <div className="simple-table-wrapper">
            <div className="simple-table-area">
              <table>
                <thead>
                  <tr>
                    {headers.map((header, idx) => (
                      <th key={idx}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {datas.map((data) => {
                    return (
                      <tr>
                        <td>{data.key}</td>
                        <td>{data.value}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>
                      <ValidationInput
                        value={keyInput}
                        onChange={(e) => setKeyInput(e.target.value)}
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export const ValidationCheckBox = ({
  validchk = "",
  invalidMsg,
  _ref,
  label,
  labelPosition = "right",
  id,
  ...attr
}) => {
  return (
    <>
      <span
        data-type={`input-checkbox`}
        className={
          validchk ? validchk + " iv-base-label valid-chk" : "iv-base-label"
        }
        data-position={labelPosition}
      >
        {(labelPosition === "left" || labelPosition === "top") && label ? (
          <label
            className="form-label"
            data-position={labelPosition}
            htmlFor={id}
            style={{ marginRight: "1rem" }}
          >
            {label}
          </label>
        ) : null}
        <span className="form-box">
          <input
            className={`form-control tooltip`}
            ref={_ref}
            type="checkbox"
            {...attr}
            id={id}
          />
          {validchk ? (
            <>
              <StyledButton
                data-bg="transparent"
                type="icon"
                data-outline="transparent"
                size="sm"
                className="icon-valid"
              >
                <SVG
                  src="assets/images/common/icon_valid.svg"
                  fill={validchk === "success" ? "#198754" : "#EB5757"}
                ></SVG>
              </StyledButton>
            </>
          ) : null}
        </span>
        {(labelPosition === "right" || labelPosition === "bottom") && label ? (
          <label
            className="form-label"
            data-position={labelPosition}
            htmlFor={id}
            style={{ marginLeft: "1rem" }}
          >
            {label}
          </label>
        ) : null}
      </span>
      {invalidMsg ? (
        <>
          <div className="invalid-feedback">{invalidMsg}</div>
        </>
      ) : null}
    </>
  );
};
