import React from "react";
import "./style.scss";

export const FormValidation = (props) => {
  return (
    <form
      className={`needs-validation${props.validate ? ` was-validated` : ``}`}
      noValidate
    >
      {props.children}
    </form>
  );
};
