import * as React from "react";
import { StyledButton } from "../../atoms/button";
import MessageLayout from '../../templetes/messageLayout'
import './style.scss'
const ConfirmPopup = () => {
  return (
    <div id="ConfirmPopup" className="popup-layout-wrap">
      <div className='layout-table' theme='primary'>
        <MessageLayout
          closePopup={closeConfirm}
          theme='primary'
          button={
            <>
              <StyledButton id="confirm_yes" type='text' data-bg='primary' size='sm'>
                Yes
              </StyledButton>
              <StyledButton id="confirm_no" type='text' data-bg='darkgray' size='sm' onClick={closeConfirm}>
                No
              </StyledButton>
            </>

          }>
          <div id="confirmBody">

          </div>
        </MessageLayout>
      </div>
    </div>
  );
};

export default ConfirmPopup;

export const confirm = (message) => {
  if (!document.getElementById("ConfirmPopup").classList.contains("on")) {
    document.getElementById("confirmBody").innerText = message
    return new Promise((resolve) => {
      document.getElementById("confirm_yes").addEventListener('click', () => {
        closeConfirm()
        resolve(true)
      }, { once: true })
      document.getElementById("confirm_no").addEventListener('click', () => {
        closeConfirm()
        resolve(false)
      }, { once: true })
      document.getElementById("ConfirmPopup").classList.add("on")
    })


  }
}
const closeConfirm = () => {
  if (document.getElementById("ConfirmPopup").classList.contains("on")) {
    document.getElementById("ConfirmPopup").classList.remove("on")
  }
}