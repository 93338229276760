import React from "react";
import { ContentsBasic } from "../../organisms/contentsBasic";
import IntroText from "../../organisms/introText/index";
import Footer from "../../organisms/footerBasic/index";
import "./style.scss";
import Alert from "../../common/alert";
import ConfirmPopup from "../../common/confirm";
import YesConfirmPopup from "../../common/yesConfirm";

const IntroLayout = ({ children, config }) => {
  return (
    <>
      <div
        id="layout"
        className="layout-intro"
        style={{
          backgroundImage: "url(./assets/images/intro/layout_intro_bg1.jpg)",
        }}
      >
        <ContentsBasic>
          <IntroText
            title={
              <span>
                Welcome. <br />
                Experience our innovation <br />
                with Cloud EDI Platform 'Connect'
              </span>
            }
            description={<span>Learn more about CONNECT</span>}
            buttonHref={config.introLearnMoreUrl}
          ></IntroText>
          {children}
        </ContentsBasic>
        <Footer></Footer>
      </div>
      <Alert />
      <ConfirmPopup />
      <YesConfirmPopup />
    </>
  );
};

export default IntroLayout;
