import React, { useState } from "react";
import PopupLayout from "../../components/templetes/popupLayout";
import { StyledButton } from "../../components/atoms/button";
import { ValidationInput } from "../../components/atoms/validation/ValidationInput";

const PasswordChangePopup = ({ changePassword, closePopup }) => {
  const [validate, setValidate] = useState(false);
  const [currentPw, setCurrentPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [confirmNewPw, setConfirmNewPw] = useState("");

  const onSubmit = () => {
    setValidate(true);
    if (currentPw === "" || newPw === "" || confirmNewPw === "") return;
    if (newPw !== confirmNewPw) {
      alert("Incorrect confirm password");
      return;
    }
    changePassword(
      {
        currentPassword: currentPw,
        newPassword: newPw,
      },
      () => {
        setValidate(false);
        closePopup();
        alert("Your Password has been changed. \n Please login again");
      }
    );
  };

  return (
    <>
      <PopupLayout
        theme="primary"
        data-size="lg"
        title="Change Password"
        closePopup={closePopup}
        button={
          <StyledButton
            type="text"
            data-bg="primary"
            size="md"
            onClick={onSubmit}
          >
            Confirm
          </StyledButton>
        }
      >
        <div style={{ paddingLeft: "3%", paddingRight: "3%" }}>
          <ValidationInput
            label="Current password"
            type="password"
            value={currentPw}
            onChange={(e) => setCurrentPw(e.target.value)}
            showValidate={validate}
            invalidMsg="current password is required."
          />
          <ValidationInput
            label="New password"
            type="password"
            value={newPw}
            onChange={(e) => setNewPw(e.target.value)}
            showValidate={validate}
            invalidMsg="new password is required."
          />
          <ValidationInput
            label="Confirm new password"
            type="password"
            value={confirmNewPw}
            onChange={(e) => setConfirmNewPw(e.target.value)}
            showValidate={validate}
            invalidMsg="confirm password is required."
          />
        </div>
      </PopupLayout>
    </>
  );
};

export default PasswordChangePopup;
