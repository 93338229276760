import axios from 'axios'
axios.defaults.withCredentials = true
const instance = axios.create({
    headers: {
        //'Pragma': 'no-cache',
        withCredentials: true
    }
})
const API = () => {
    return instance
}

export function initAxios(history) {
    instance.interceptors.request.use((config) => {
        return config
    }, (error) => {
        return Promise.reject(error)
    })
    instance.interceptors.response.use(
        (res) => {
            return {
                ...res,
                history
            }
        },
        (error) => {
            let message = null;
            if (error.response.data)
                message = error.response.data;
            if (error.response.status !== 200) {
                alert(message !== null ? message : error.message);
            }
            return Promise.reject(error)
        }
    )
}
export default API