import React, { useEffect } from "react";
import "./style.scss";
import { StyledButtonArea } from "../../molecules/buttonArea";
import { StyledButton } from "../../atoms/button";
import SVG from "react-inlinesvg";

const MessageLayout = ({
  theme = "primary",
  closePopup = () => {},
  children,
  button,
  ...props
}) => {
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") closePopup();
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <div className="message-layout" theme={theme} {...props}>
      <div className="message-layout-inner">
        <div className="message-header">
          <p>Message</p>
          <StyledButton
            size="md"
            type="icon"
            data-outline={"transparent"}
            data-bg={"darkgray"}
            onClick={closePopup}
          >
            <SVG
              src={"assets/images/common/icon_cancel.svg"}
              fill="#ffffff"
              width="24"
              height="24"
            ></SVG>
          </StyledButton>
        </div>
        <div className="message-body ">{children}</div>
        <div className="message-footer">
          <StyledButtonArea>{button}</StyledButtonArea>
        </div>
      </div>
    </div>
  );
};

export default MessageLayout;