import React from "react";
import "./style.scss";

export const StyledButton = ({
  tag = "button",
  bg = "primary",
  size = "md",
  outline = "",
  type = "text",
  children,
  ...attr
}) => {
  return (
    <React.Fragment>
      {tag === "button" ? (
        <button
          className="btn"
          data-bg={bg}
          data-size={size}
          data-outline={outline}
          data-type={type}
          {...attr}
        >
          {children}
        </button>
      ) : null}
      {tag === "a" ? (
        <a
          className="btn"
          data-bg={bg}
          data-size={size}
          data-outline={outline}
          data-type={type}
          {...attr}
        >
          {children}
        </a>
      ) : null}
    </React.Fragment>
  );
};
