import React, { useEffect, useState } from "react";
import { FormValidation } from "../../molecules/form";
import { ValidationCheckbox, ValidationInput } from "../../atoms/input";
import { StyledButtonArea } from "../../molecules/buttonArea";
import { StyledButton } from "../../atoms/button";
import "./style.scss";
import { useSearchParams } from "react-router-dom";
import PasswordChangePopup from "../../../pages/component/PasswordChangePopup";
import ForgotUserIDPopup from "../../../pages/component/ForgotUserIDPopup";
import ForgotPasswordPopup from "../../../pages/component/ForgotPasswordPopup";

const LoginForm = ({
  onLogin,
  title,
  config,
  findUserID,
  resetPassword,
  changeUserPw,
  closePwChangePopup,
  pwChangePopupFlag,
  onClickSignUp = ()=>{}
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const [forgotIDPopupFlag, setForgotIDPopupFlag] = useState(false);
  const [forgotPwPopupFlag, setForgotPwPopupFlag] = useState(false);

  useEffect(() => {
    setUserId("");
    setPassword("");
  }, [title]);

  const changePassword = (request, cb) => {
    changeUserPw(request, () => {
      setUserId("");
      setPassword("");
      cb();
    });
  };

  return (
    <div className="form">
      <h3>{title}</h3>
      <FormValidation>
        <div className="inputs">
          <ValidationInput
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Please enter your ID"
            type="text"
            required={true}
          />
          <ValidationInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Please enter your password"
            type="password"
            required={true}
          />
        </div>
        <div className="util">
          <div className="input-width-label">
            <ValidationCheckbox
              id="rmbMeChkbox"
              label="Remember me"
              labelPosition="right"
              type="checkbox"
              required={false}
            />
          </div>
          <ul className="list">
            <li>
              <a onClick={() => setForgotIDPopupFlag(true)}>Forgot your ID?</a>
            </li>
            <li>
              <a onClick={() => setForgotPwPopupFlag(true)}>
                Forgot your Password?
              </a>
            </li>
          </ul>
        </div>
        <StyledButtonArea>
          <StyledButton
            size="lg"
            bg="primary"
            onClick={(e) => {
              e.preventDefault();
              const params = new URLSearchParams();
              params.append("userId", userId);
              params.append("password", password);
              onLogin(params, searchParams.get("redirect"));
            }}
          >
            LOG IN
          </StyledButton>
        </StyledButtonArea>
        <div className="tip">
          Not registered? <a onClick={onClickSignUp}>Signup now</a>
        </div>
      </FormValidation>
      {forgotIDPopupFlag && (
        <ForgotUserIDPopup
          findUserID={findUserID}
          closePopup={() => setForgotIDPopupFlag(false)}
        />
      )}
      {forgotPwPopupFlag && (
        <ForgotPasswordPopup
          resetPassword={resetPassword}
          closePopup={() => setForgotPwPopupFlag(false)}
        />
      )}
      {pwChangePopupFlag && (
        <PasswordChangePopup
          changePassword={changePassword}
          closePopup={closePwChangePopup}
        />
      )}
    </div>
  );
};

export default LoginForm;
