import * as React from "react";
import "./style.scss";

export const TabArea = ({ tabData, mode, setMode }) => {
  let selectTab = null;
  for (let elem of tabData) {
    if (elem.key === mode) selectTab = elem;
  }
  if (selectTab === null) selectTab = tabData[0];

  return (
    <div>
      <div className="tab-area">
        <Tab tabData={tabData} setMode={setMode} mode={mode}></Tab>
      </div>
      {selectTab.content}
    </div>
  );
};

export const Tab = ({ tabData, setMode, mode }) => {
  return (
    <ul className="tab-list">
      {tabData.map((elem, index) => (
        <React.Fragment key={index}>
          <TabItem info={elem} setMode={setMode} mode={mode}></TabItem>
        </React.Fragment>
      ))}
    </ul>
  );
};

export const TabItem = ({ info, setMode, mode }) => {
  return (
    <li
      className={`tab-item ` + (mode === info.key ? "on" : "")}
      onClick={() => {
        setMode(info.key);
      }}
    >
      {info.name}
    </li>
  );
};
