import React from "react";
import "./style.scss";

export const ContentsBasic = (props) => {
  return (
    <React.Fragment>
      <div id="contents">{props.children}</div>
    </React.Fragment>
  );
};
