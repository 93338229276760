import React, { useEffect } from "react";

const formRef = React.createRef();
const Validation = (props) => {
  return (
    <form ref={formRef} noValidate>
      {props.children}
    </form>
  );
};
export default Validation;
