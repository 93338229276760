import { confirm } from "../components/common/yesConfirm";
import API from "../utils/API";

const initialState = {
  introConfig: null,
  userId: "",
  pwChangePopupFlag: false,
  forgotPopup: {
    userId: "",
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_INTRO_CONFIG: {
      return {
        ...state,
        introConfig: action.payload,
      };
    }
    case PW_CHANGE_FORCED: {
      return {
        ...state,
        userId: action.payload.userId,
        pwChangePopupFlag: true,
      };
    }
    case CLOSE_PW_CHANGE_POPUP:
      return {
        ...state,
        pwChangePopupFlag: false,
      };
    case FIND_USER_ID:
      return {
        ...state,
        forgotPopup: {
          ...state.forgotPopup,
          userId: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
export default reducer;

const GET_INTRO_CONFIG = "intro/GET_INTRO_CONFIG";
export const getIntroConfig = () => (dispatch) => {
  API()
    .get("/intro/rest/config")
    .then((res) => {
      dispatch({
        type: GET_INTRO_CONFIG,
        payload: res.data,
      });
    });
};

const PW_CHANGE_FORCED = "auth/PW_CHANGE_FORCED";
export const onLoginConnect = (params, redirectUrl) => (dispatch) => {
  API()
    .post(
      `/auth/rest/connect/login${redirectUrl && redirectUrl !== null
        ? `?redirect=${encodeURIComponent(redirectUrl)}`
        : ""
      }`,
      params,
      { withCredentials: true }
    )
    .then(async (res) => {
      switch (res.data.type) {
        case "INVALID_USER_PASSWD":
        case "USER_NOT_FOUND":
        case "USER_LOCKED":
          alert(res.data.message);
          break;
        case "PW_CHANGE_FORCED":
          await confirm(res.data.message);
          dispatch({
            type: PW_CHANGE_FORCED,
            payload: {
              userId: params.get("userId"),
            },
          });
          break;
        case "PW_CHANGE_REQUIRED":
          await confirm(res.data.message);
          window.location.replace(res.data.redirect);
          break;
        case "SUCCESS":
          window.location.replace(res.data.redirect);
          break;
        default:
          break;
      }
    });
};

export const onLoginWEdi = (params, redirectUrl) => (dispatch) => {
  API()
    .post(
      `/auth/rest/wedi/login${redirectUrl && redirectUrl !== null
        ? `?redirect=${encodeURIComponent(redirectUrl)}`
        : ""
      }`,
      params,
      { withCredentials: true }
    )
    .then(async (res) => {
      switch (res.data.type) {
        case "INVALID_USER_PASSWD":
        case "USER_NOT_FOUND":
        case "USER_LOCKED":
          alert(res.data.message);
          break;
        case "PW_CHANGE_FORCED":
          await confirm(res.data.message);
          dispatch({
            type: PW_CHANGE_FORCED,
            payload: {
              userId: params.get("userId"),
            },
          });
          break;
        case "PW_CHANGE_REQUIRED":
          await confirm(res.data.message);
          window.location.replace(res.data.redirect);
          break;
        case "SUCCESS":
          window.location.replace(res.data.redirect);
          break;
        default:
          break;
      }
    });
};
export const onLoginAPIGateway = (params, redirectUrl) => (dispatch) => {
  API()
    .post(
      `/auth/rest/apigw/login${redirectUrl && redirectUrl !== null
        ? `?redirect=${encodeURIComponent(redirectUrl)}`
        : ""
      }`,
      params,
      { withCredentials: true }
    ).then(async (res) => {
      switch (res.data.type) {
        case "INVALID_USER_PASSWD":
        case "USER_NOT_FOUND":
        case "USER_LOCKED":
          alert(res.data.message);
          break;
        case "PW_CHANGE_FORCED":
          await confirm(res.data.message);
          dispatch({
            type: PW_CHANGE_FORCED,
            payload: {
              userId: params.get("userId"),
            },
          });
          break;
        case "PW_CHANGE_REQUIRED":
          await confirm(res.data.message);
          window.location.replace(res.data.redirect);
          break;
        case "SUCCESS":
          window.location.replace(res.data.redirect);
          break;
        default:
          break;
      }
    })
}
const CLOSE_PW_CHANGE_POPUP = "auth/CLOSE_PW_CHANGE_POPUP";
export const closePwChangePopup = () => (dispatch) => {
  dispatch({
    type: CLOSE_PW_CHANGE_POPUP,
  });
};

export const changeUserPw = (request, userType, callback) => (dispatch) => {
  API()
    .post(`/auth/rest/password/change?userType=${userType}`, request)
    .then((res) => {
      callback();
    });
};

const FIND_USER_ID = "auth/FIND_USER_ID";
export const findUserID = (request, userType, callback) => (dispatch) => {
  API()
    .post(`/auth/rest/user/find?userType=${userType}`, request)
    .then((res) => {
      dispatch({
        type: FIND_USER_ID,
        payload: res.data,
      });
      if (callback) callback();
    });
};

export const resetPassword = (request, userType, callback) => (dispatch) => {
  API()
    .post(`/auth/rest/password/reset?userType=${userType}`, request)
    .then((res) => {
      if (callback) callback();
    });
};

export const registerApiUser = (user, callbackFn) => (dispatch) => {
  API().post(`/auth/rest/register-user`, user).then(res => {
    if (res.data.success && callbackFn)
      callbackFn()
  })
}
export const whoami = (callbackFn) => {
  API().get(`/auth/rest/whoami`).then(res => {
    if (res.data.redirectUrl != null && callbackFn)
      callbackFn(res.data.redirectUrl)
  })
}