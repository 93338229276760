import React, { useEffect } from "react";
import "./style.scss";
import { StyledButtonArea } from "../../molecules/buttonArea";
import { StyledButton } from "../../atoms/button";

import SVG from "react-inlinesvg";
import { useState } from "react";
import { useRef } from "react";

const PopupLayout = ({
  theme = "primary",
  className = "",
  title,
  closePopup,
  button,
  children,
  style,
  overflowY = "auto",
}) => {
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") closePopup();
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  const [dragInfo, setDragInfo] = useState({})
  const layOutRef = useRef()
  return (
    <div className="popup-layout-wrap">
      <div
        className={`popup-layout ${className}`}
        theme={theme}
        ref={layOutRef}
        style={{
          ...style,
          ...dragInfo
        }}
      >
        <div className="popup-layout-inner">
          <div className="popup-header" draggable onDrag={e => {
            if (e.clientX > 0 && e.clientY > 0)
              setDragInfo({
                position: 'absolute',
                top: e.clientY + (layOutRef.current.offsetHeight / 2 - 20),
                left: e.clientX
              })
          }}>
            <h6>{title}</h6>
            <StyledButton
              size="sm"
              type="icon"
              data-outline={"transparent"}
              data-bg={"transparent"}
              onClick={closePopup}
            >
              <SVG
                src={"assets/images/common/icon_close.svg"}
                fill="#464646"
                width="20"
                height="20"
              ></SVG>
            </StyledButton>
          </div>
          <div className="popup-body " style={{ overflowY: overflowY }}>
            {children}
          </div>
          <div className="popup-footer">
            <StyledButtonArea>{button}</StyledButtonArea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupLayout;
