import * as React from "react";
import { StyledButton } from "../../atoms/button";
import MessageLayout from '../../templetes/messageLayout'
import './style.scss'
const Alert = () => {
  React.useEffect(() => {
    window.alert = showAlert
  }, [])
  return (
    <div id="AlertPopup" className="popup-layout-wrap">
      <div className='layout-table' theme='primary'>
        <MessageLayout
          closePopup={closeAlert}
          theme='primary'
          title='Upload Package Version'
          button={
            <StyledButton type='text' data-bg='primary' size='md' onClick={closeAlert}>
              OK
            </StyledButton>
          }>
          <div id="alertBody">

          </div>
        </MessageLayout>
      </div>
    </div>
  );
};

export default Alert;

const showAlert = (message) => {
  if (!document.getElementById("AlertPopup").classList.contains("on")) {
    document.getElementById("alertBody").innerText = message
    document.getElementById("AlertPopup").classList.add("on")
  }
}
const closeAlert = () => {
  if (document.getElementById("AlertPopup").classList.contains("on")) {
    document.getElementById("AlertPopup").classList.remove("on")
  }
}