import "./App.css";
import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import IntroContainer from "./pages/containers/IntroContainer";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<IntroContainer />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
