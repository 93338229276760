import * as React from "react";

const LogoSymbol = (props) => (
  <svg
    width={31}
    height={Math.round(Number(props.width) * 0.28)}
    viewBox="0 0 31 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.585 27.35a5.415 5.415 0 1 0 0-10.832 5.415 5.415 0 0 0 0 10.831Z"
      fill={props.fill !== undefined ? props.fill : "#DBD529"}
    />
    <path
      d="M5.858 27.35a5.415 5.415 0 1 0 0-10.832 5.415 5.415 0 0 0 0 10.831Z"
      fill={props.fill !== undefined ? props.fill : "#126690"}
    />
    <path
      d="M15.335 11.102a5.415 5.415 0 1 0 0-10.83 5.415 5.415 0 0 0 0 10.83Z"
      fill={props.fill !== undefined ? props.fill : "#D61518"}
    />
  </svg>
);

export default LogoSymbol;
