import React from "react";
import LogoTag from "../../atoms/logo";
import "./style.scss";

const Footer = () => (
  <div id="footer">
    <div className="contact">
      <LogoTag width="110" fill="#fff"></LogoTag>
      <p style={{ fontSize: 16 }}>커넥트 서비스 2.0</p>
      <p style={{ position: 'relative', top: 2 }}>Support : edi-help@inspien.co.kr</p>
    </div>
    <div className="copyright">
      <p>Copyright © INSPIEN All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
