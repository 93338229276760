import React from "react";
import Logo from "../../../assets/images/common/logo";
import LogoVertical from "../../../assets/images/common/logoVertical";
import LogoSymbol from "../../../assets/images/common/logoSymbol";

const LogoTag = (props) => (
  <React.Fragment>
    {props.type === "vertical" ? (
      <LogoVertical {...props}></LogoVertical>
    ) : props.type === "symbol" ? (
      <LogoSymbol {...props}></LogoSymbol>
    ) : (
      <Logo {...props}></Logo>
    )}
  </React.Fragment>
);

export default LogoTag;
