import React from "react";
import "./style.scss";

export const StyledButtonArea = (props) => {
  const align = props.align ? props.align : null;
  return (
    <div className='btn-area' data-align={align}>
      {props.children}
    </div>
  );
};
