import React, { useState } from "react";
import PopupLayout from "../../components/templetes/popupLayout";
import { StyledButton } from "../../components/atoms/button";
import { ValidationInput } from "../../components/atoms/validation/ValidationInput";
import { StyledButtonArea } from "../../components/molecules/buttonArea";

const ForgotPasswordPopup = ({ resetPassword, closePopup }) => {
  const [validate, setValidate] = useState(false);
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(0);

  const onSubmit = () => {
    setValidate(true);
    if (userId === "" || email === "") return;
    resetPassword(
      {
        userId: userId,
        email: email,
      },
      () => {
        setPage(1);
      }
    );
  };

  return (
    <>
      <PopupLayout
        theme="primary"
        data-size="lg"
        title="Reset Password"
        closePopup={closePopup}
        button={
          <StyledButtonArea>
            {page === 0 && (
              <StyledButton
                type="text"
                data-bg="primary"
                size="md"
                onClick={onSubmit}
              >
                Reset Password
              </StyledButton>
            )}
          </StyledButtonArea>
        }
      >
        {page === 0 && (
          <div style={{ paddingLeft: "3%", paddingRight: "3%" }}>
            <ValidationInput
              label="User ID"
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              showValidate={validate}
              invalidMsg="User ID is required."
            />
            <ValidationInput
              label="E-Mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              showValidate={validate}
              invalidMsg="E-Mail is required."
            />
          </div>
        )}
        {page === 1 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "50px",
              textAlign: "center",
              lineHeight: "normal"
            }}
          >
            We have sent a temporary password to your email({email})
          </div>
        )}
      </PopupLayout>
    </>
  );
};

export default ForgotPasswordPopup;
